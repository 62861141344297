import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import { APPLICATION_SEARCH_API } from "../config/keys"

// Images
import SearchIcon from "../images/icon/search-icon.svg"
import BlueLabel from "../images/logo-blue-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GradientImage from "../images/gradient-big.png"
import BlueLabelEU from "../images/europe/logo.svg"
import GoldLabelEU from "../images/europe/download-logo-gold.svg"
import SilverLabelEU from "../images/europe/download-logo-silver.svg"

const SupplierDatabase = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueryText, setSearchQueryText] = useState("")
  const [listOfCompamies, setListOfCompanies] = useState([])
  const [errorMessage, setErrorMessage] = useState({
    state: false,
    message: "",
  })

  function updateSearchQuery(event) {
    setSearchQuery(event.target.value)
  }

  function handleEnter(event) {
    if (event.key !== "Enter" && event.key !== "NumpadEnter") {
      return
    }
    searchForCompanies(event)
  }

  async function searchForCompanies(event) {
    if (event !== undefined) event.preventDefault()
    if (searchQuery.length < 3) {
      setErrorMessage({
        state: true,
        message: "Bitte geben sie zumindest drei zusammenhängende Zeichen ein",
      })
      return
    } else {
      setErrorMessage({ state: false, message: "" })

      const response = await fetch(`${APPLICATION_SEARCH_API}?q=${searchQuery}`)
      const responseData = await response.json()

      const companiesList = responseData.data
      setSearchQueryText(searchQuery)
      setListOfCompanies(companiesList)
    }
  }

  function getLogo(type) {
    switch (type) {
      case 0:
        return process.env.GATSBY_VARIANT === "Austria" ? BlueLabel : BlueLabelEU
      case 1:
        return process.env.GATSBY_VARIANT === "Austria" ? GoldLabel : GoldLabelEU
      case 2:
        return process.env.GATSBY_VARIANT === "Austria" ? SilverLabel : SilverLabelEU
      default:
        return process.env.GATSBY_VARIANT === "Austria" ? BlueLabel : BlueLabelEU
    }
  }

  return (
    <React.Fragment>
      <PageTitle
        title={`Suppliers and organizations with the Cyber ​​Trust ${process.env.GATSBY_VARIANT} label`}
      />
      <Layout>
        <div className="request-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Labelled Supplier Database</a>
              </li>
            </ul>
          </div>
          <div className="request">
            <h1>
              {`Suppliers and organizations with the Cyber ​​Trust ${process.env.GATSBY_VARIANT}`}
              <span className="sup" style={{ color: "white" }}>
                ®
              </span>{" "}
              Label
            </h1>

            <div className="request__wrapper">
              <div className="request__wrapper--info">
                <p>
                  The Cyber Trust Austria label gives organizations the
                  opportunity to demonstrate to others that they have
                  implemented essential minimum security measures for cyber
                  security and that the topic has an important status in the
                  organization.
                </p>
              </div>

              <h2>
                Search for suppliers and organisations with a valid Cyber Trust
                Austria<span className="sup">®</span> label here:
              </h2>
              <div className="request__search">
                <form className="form__request" onSubmit={searchForCompanies}>
                  <div className="form__request--group">
                    <div className="icon">
                      <img
                        src={SearchIcon}
                        alt="search"
                        title="search"
                        className="mw-100"
                      ></img>
                    </div>
                    <input
                      type="search"
                      placeholder="Please enter the name of the company"
                      value={searchQuery}
                      onChange={updateSearchQuery}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <div className="form__request--button">
                    <button type="submit" className="page__btn page__btn--blue">
                      Search
                    </button>
                  </div>
                </form>
              </div>

              <div className="request__block">
                <div className="page__flex">
                  <p
                    className="popup__error-indicator"
                    style={{ display: `${errorMessage.state ? "" : "none"}` }}
                  >
                    {errorMessage.message}
                  </p>
                  <div className="request__block--box">
                    <div className="title">
                      {searchQueryText === "" ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          Currently valid labels from organisations with the
                          search term «{searchQueryText}»{" "}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="request__block--box"></div>
                </div>
              </div>

              <div className="request__description">
                {listOfCompamies.length > 0 && (
                  <div className="request__table">
                    <div className="request__table--header">
                      <div className="request__table--header--cell">
                        Name of company
                      </div>
                      <div className="request__table--header--cell">
                        Company number / ID
                      </div>
                      <div className="request__table--header--cell">Label</div>
                    </div>
                    {listOfCompamies.map((company, index) => {
                      return (
                        <div className="request__table--row" key={index}>
                          <div className="request__table__cell_title">
                            Name of company <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            {company.org_name}
                          </div>
                          <div className="request__table__cell_title">
                            Company number / ID <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            {company.registered_number}
                          </div>
                          <div className="request__table__cell_title">
                            Label <br></br>
                          </div>
                          <div className="request__table--row--cell">
                            <div className="image">
                              <img
                                className="mw-100"
                                src={getLogo(company.gold_label)}
                                alt={company.org_name}
                                title={company.org_name}
                                // if is_outdated is true, the image should be greyed out
                                // -webkit-filter: grayscale(0%);
                                // filter: grayscale(0%);
                                style={{
                                  filter: company.is_outdated
                                    ? "grayscale(100%)"
                                    : "unset",
                                  opacity: company.is_outdated ? 0.25 : "unset",
                                  webkitFilter: company.is_outdated
                                    ? "grayscale(100%)"
                                    : "unset",
                                }}
                              />
                              {company.is_outdated ? (
                                <div className="expired">EXPIRED</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default SupplierDatabase
